import { APIUser } from "discord-api-types/v10";
import _ from "lodash";
import { Airbase } from "../data/airbases";
import { DCSDrawingLayers } from "../drawings";
import { Theater } from "../map";
import {
  AirbaseType,
  AppRoutes,
  Coalition,
  DCSDrawingObject,
  DCSGroupData,
  DCSNavPoint,
  DCSZoneData,
  FirebaseMetadata,
} from "../types";
import { FragOrderDoc } from "./FragOrder";
import { JobType } from "./Job";
import { RadioPresetConfig } from "./RadioPresetConfig";
import { SignupRule } from "./SignupRecord";

export type TaskingState = {
  theater: Theater;
  plannedGroups: DCSGroupData[];
  airbases: Airbase[];
  opforAirbases: Airbase[];
  opforVehicles: DCSGroupData[];
  bluforVehicles: DCSGroupData[];
  supportAssets: DCSGroupData[];
  zones: DCSZoneData[];
  opforPlanes: DCSGroupData[];
  drawingData: DCSDrawingObject[];
  time: string;
  weather: {
    temperature: string;
    qnh: number;
    wind: number[];
  };
  bluforStatics?: DCSGroupData[];
  opforStatics?: DCSGroupData[];
  bullseye?: { x: number; y: number };
  briefingText?: string;
  controlPoints?: DCSNavPoint[];
  radioPresets?: RadioPresetConfig;
  bluforShips?: DCSGroupData[];
  opforShips?: DCSGroupData[];
};

export interface ControlMeasureEditor {
  discordUserID: string;
  displayName: string;
  coalition: Coalition;
}

const publicHost =
  process.env.CONTEXT === "branch-preview" ||
  process.env.CONTEXT === "deploy-preview"
    ? "https://preview.fragorders.com"
    : process.env.FRAG_ORDER_PUBLIC_HOST;
export class PublishManifest {
  id: string;
  coalition: Coalition;
  fragOrderID: string;
  title: string;
  // Deprecated!
  taskingData: string;
  planningRules?: PlanningRule[];
  publishOpts: PublishOpts;
  assetURLs?: string[];
  controlMeasureEditors?: ControlMeasureEditor[];
  signupsEnabled?: boolean;
  signupRules?: SignupRule[];
  discordGuildID?: string;
  version?: number;
  bundleAddress?: string;
}

export type PublishManifestV2 = Omit<PublishManifest, "taskingData">;

export type PublishOpts = {
  showHidden: boolean;
  showHiddenOnMFD: boolean;
  showZones: boolean;
  showHiddenZones: boolean;
  showGroups: boolean;
  showIndividualUnits: boolean;
  showFriendlyGroups: boolean;
  showFriendlyUnits: boolean;
  showDrawing: boolean;
  showDrawingLayerAuthor: boolean;
  showDrawingLayerCommon: boolean;
  showDrawingLayerCoalition: boolean;
  allowPlannerRadioFreqs: boolean;
  allowPlannersLaserCodes: boolean;
  showOpforStatics: boolean;
};

export type PlanningRule = {
  isRoleRule: boolean;
  discordRoleID: string | null;
  discordUserID: string;
  discordDisplayText: string;
  plannableGroupNames: string[];
};

export function defaultPublishOpts(): PublishOpts {
  return {
    showHidden: false,
    showHiddenOnMFD: false,
    showZones: false,
    showHiddenZones: false,
    showIndividualUnits: false,
    showGroups: true,
    showFriendlyGroups: true,
    showFriendlyUnits: false,
    showDrawing: true,
    showDrawingLayerAuthor: false,
    showDrawingLayerCommon: true,
    showDrawingLayerCoalition: true,
    allowPlannerRadioFreqs: false,
    allowPlannersLaserCodes: true,
    showOpforStatics: true,
  };
}

export type PublishManifestDoc = PublishManifest & FirebaseMetadata;

export function createPublishManifest(
  fragOrder: FragOrderDoc,
  coalition: Coalition,
  opts: PublishOpts,
  t?: TaskingState
): PublishManifest {
  return {
    coalition,
    fragOrderID: fragOrder.id,
    // Keeping for backward compatibility
    taskingData: t ? JSON.stringify(t) : null,
    title: fragOrder.name,
    publishOpts: opts,
    discordGuildID: fragOrder.discordGuildID,
  } as PublishManifest;
}

export function sanitizeTaskingState(
  fragOrder: FragOrderDoc,
  opts: PublishOpts,
  coalition: Coalition,
  ts: TaskingState
) {
  const t = _.cloneDeep(ts);

  if (opts.showGroups) {
    if (!opts.showHidden) {
      t.opforVehicles = _.filter(t.opforVehicles, (g) => !g.hidden);

      t.opforShips = _.filter(t.opforShips, (g) => !g.hidden);
      t.bluforShips = _.filter(t.bluforShips, (g) => !g.hidden);
    }

    if (!opts.showHiddenOnMFD) {
      t.opforVehicles = _.filter(t.opforVehicles, (g) => !g.hiddenOnMFD);

      t.opforShips = _.filter(t.opforShips, (g) => !g.hiddenOnMFD);
    }

    if (!opts.showHidden && !opts.showHiddenOnMFD) {
      t.opforVehicles = _.filter(
        t.opforVehicles,
        (g) => !g.hidden && !g.hiddenOnMFD
      );

      t.opforShips = _.filter(t.opforShips, (g) => !g.hidden && !g.hiddenOnMFD);
      t.bluforShips = _.filter(
        t.bluforShips,
        (g) => !g.hidden && !g.hiddenOnMFD
      );
    }
  } else {
    t.opforVehicles = [];
  }

  t.plannedGroups = _.filter(
    t.plannedGroups,
    (g) => !g.hidden && !g.hiddenOnPlanner
  );

  // if (!opts.showIndividualUnits) {
  //   _.each(t.opforVehicles, (g) => (g.units = []));
  // }

  if (!opts.showDrawing) {
    t.drawingData = [];
  } else {
    let drawing: DCSDrawingObject[] = [];
    if (opts.showDrawingLayerAuthor) {
      const author = _.filter(ts.drawingData, {
        layerName: DCSDrawingLayers.Author,
      });

      drawing = drawing.concat(author);
    }

    if (opts.showDrawingLayerCommon) {
      const common = _.filter(ts.drawingData, {
        layerName: DCSDrawingLayers.Common,
      });

      drawing = drawing.concat(common);
    }

    if (opts.showDrawingLayerCoalition) {
      const coa = _.filter(
        ts.drawingData,
        (d) => d.layerName === DCSDrawingLayers[_.capitalize(coalition)]
      );

      drawing = drawing.concat(coa);
    }

    t.drawingData = drawing;
  }

  if (opts.showZones) {
    let pred = (z) => !z.hidden;
    if (opts.showHiddenZones) {
      // return all zones
      pred = (z) => !!z;
    }
    t.zones = _.filter(ts.zones, pred);
  } else {
    t.zones = [];
  }

  if (!opts.showOpforStatics) {
    t.opforStatics = [];

    t.opforAirbases = _.filter(
      t.opforAirbases,
      (a) => a.Type !== AirbaseType.FARP
    );
  }

  if (!opts.showFriendlyGroups) {
    t.bluforVehicles = [];
  }

  const hasPresetJob =
    _.includes(fragOrder.jobs, JobType.RadioPresetConfig) &&
    fragOrder.jobSettings[JobType.RadioPresetConfig];

  if (hasPresetJob) {
    t.radioPresets = JSON.parse(
      fragOrder.jobSettings[JobType.RadioPresetConfig] as string
    );
  }

  return t;
}

export function publishManifestNavURL(pm: PublishManifestDoc) {
  return `${publicHost}${AppRoutes.PublicFragOrder}/${pm.id}`;
}

export function plannableGroupsForUser(
  pm: PublishManifestDoc,
  user: APIUser
): string[] {
  const formattedRules = _.map(pm.planningRules, (r) => ({
    ...r,
    discordUserID: r.discordUserID?.replace("u_", ""),
  }));
  const ruleForUser = _.find(formattedRules, { discordUserID: user?.id });

  return ruleForUser?.plannableGroupNames || [];
}
