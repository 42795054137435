import _ from "lodash";
import * as React from "react";
import { GroupWithPlan } from "../../contexts/PlanManagerContext";
import { getRouteExtent } from "../../lib/extent";
import { ExternalDataRecord } from "../../lib/models/ExternalDataRecord";
import { GroupLineupCardSettings } from "../../lib/models/Job";
import { RadioChannelOverride } from "../../lib/models/Plan";
import {
  FuelPlan,
  getPlanItemPayload,
  PlanItem,
  PlanItemType,
  PlanNote,
} from "../../lib/models/PlanItem";
import { TaskingState } from "../../lib/models/PublishManifest";
import { NewHTMLImageGenerator } from "../../lib/services/HTMLImageGenerator";
import { NewImageComposer } from "../../lib/services/ImageComposer";
import GroupsWithWaypoints from "../MapCanvas/GroupWithRoute";
import SharedNavPointsLayer from "../MapCanvas/SharedNavPointsLayer";
import KneeboardLineupCard from "./KneeboardLineupCard";
import {
  KNEEBOARD_HEIGHT,
  KNEEBOARD_WIDTH,
  renderMap,
} from "./kneeboard_utils";

export async function renderLineupCard(
  tasking: TaskingState,
  group: GroupWithPlan,
  jobSettings?: GroupLineupCardSettings,
  radioConfig?: string,
  externalData?: ExternalDataRecord[],
  planItems?: PlanItem[]
) {
  if (!group) {
    throw new Error(`No group specified for lineup cards`);
  }
  const trimmed = _.take(
    group?.plan?.waypoints && group?.plan?.waypoints.length > 0
      ? group.plan.waypoints
      : group.waypoints,
    21
  );

  const trimmedGroup = { ...group, waypoints: trimmed };
  const composer = NewImageComposer(KNEEBOARD_HEIGHT, KNEEBOARD_WIDTH);

  const fuelPlanDoc = _.find(planItems, {
    type: PlanItemType.FuelPlan,
    group: group.name,
  });

  let parsed: FuelPlan | null = null;
  if (fuelPlanDoc) {
    parsed = JSON.parse(fuelPlanDoc.payload);
  }

  const remarks = _.find(planItems, {
    type: PlanItemType.PlanNote,
    group: group.name,
  });

  const navPoints = _.filter(planItems, {
    type: PlanItemType.NavPoint,
    group: group.name,
  });

  const radioPlanItem = _.find(planItems, {
    type: PlanItemType.RadioPresets,
    group: group.name,
  });

  let presets: RadioChannelOverride[][] = [];

  if (radioPlanItem) {
    presets = JSON.parse(radioPlanItem.payload);
  }

  const parsedRemarks = remarks
    ? (getPlanItemPayload(remarks) as PlanNote)
    : null;

  const laserConfig = _.find(planItems, {
    type: PlanItemType.LaserCodes,
    group: group.name,
  });

  const el = (
    <KneeboardLineupCard
      tasking={tasking}
      group={trimmedGroup}
      settings={jobSettings}
      radioConfig={radioConfig ? JSON.parse(radioConfig) : null}
      externalData={externalData}
      fuelPlan={parsed}
      remarks={remarks ? parsedRemarks?.text : group?.plan?.plannerNotes}
      radioPlanItems={presets}
      laserConfig={laserConfig ? JSON.parse(laserConfig.payload) : null}
    />
  );

  const g = NewHTMLImageGenerator();

  const blob = await g.renderToBlob(el, {
    height: KNEEBOARD_HEIGHT,
    width: KNEEBOARD_WIDTH - 8,
  });

  const extent = getRouteExtent(tasking.theater, trimmedGroup);

  const height = 300;
  const width = KNEEBOARD_WIDTH;

  const mapImg = (await renderMap(
    extent,
    { height: height, width: width / 2, zoomAdjust: -0.3 },
    <>
      <GroupsWithWaypoints
        groups={[trimmedGroup]}
        theater={tasking.theater}
        colorOverride="black"
        disableDistanceLabels
        declutter={false}
        renderIcon
        planItemNavPoints={navPoints}
      />
      <SharedNavPointsLayer
        theater={tasking.theater}
        bullseye={tasking.bullseye}
      />
    </>
  )) as Blob;

  composer.add(blob, { x: 4, y: 0 });
  composer.add(mapImg, {
    x: KNEEBOARD_WIDTH - width,
    y: KNEEBOARD_HEIGHT - height,
  });

  return composer;
}
